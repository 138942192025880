import { mainnet, sepolia } from "wagmi/chains";

const mainnetWithRPC = {
    ...mainnet,
    rpcUrls: {
      default: {
        http: [
          process.env.REACT_APP_RPC_URL_1,
          process.env.REACT_APP_RPC_URL_2,
          process.env.REACT_APP_RPC_URL_3,
        ],
      },
    },
};

const sepoliaWithRPC = {
    ...sepolia,
    rpcUrls: {
      default: {
        http: [
          process.env.REACT_APP_RPC_URL_1,
          process.env.REACT_APP_RPC_URL_2,
          process.env.REACT_APP_RPC_URL_3,
        ],
      },
    },
};

export const getChainConfig = () => {
    if (process.env.REACT_APP_NETWORK === "MAINNET") {
      return mainnetWithRPC;
    } else {
      return sepoliaWithRPC;
    }
};