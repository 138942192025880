import "./App.scss";
import React, { Suspense, lazy } from "react";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
  injectedWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getChainConfig } from "./chainconfig";
import { Watch } from "react-loader-spinner";

const Header = lazy(() => import("./components/header"));
const Token = lazy(() => import("./components/token"));
const Disclaimer = lazy(() => import("./components/disclaimer"));

const chainWithRPC = getChainConfig();

export const config = getDefaultConfig({
  appName: "REV Token",
  projectId: process.env.REACT_APP_PROJECT_ID,
  chains: [chainWithRPC],
  wallets: [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        trustWallet,
        walletConnectWallet,
        injectedWallet,
      ],
    },
  ],
  ssr: true,
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Router>
            <div className="App">
              <div className="customOuterContainer">
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                <Suspense fallback={<div className="loaderDiv">
                  <Watch
                    visible={true}
                    height="80"
                    width="80"
                    radius="48"
                    color="#49C3FB"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>}>
                  <Header />
                  <Routes>
                    <Route path="/" element={<Token />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    {/* Add more routes as needed */}
                  </Routes>
                </Suspense>
              </div>
            </div>
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
